import React, { useState } from "react";
import axios from "axios";
import FlipCountdown from "@rumess/react-flip-countdown";
import bg from "./assets/images/bg-stars.svg";
import bottomBg from "./assets/images/pattern-hills.svg";
import fbIcon from "./assets/images/icon-facebook.svg";
import pinterestIcon from "./assets/images/icon-pinterest.svg";
import instagramIcon from "./assets/images/icon-instagram.svg";
import logo from "./assets/images/logo.svg";

function App() {

  const [email, setEmail] = useState(""); // State for email input
  const [message, setMessage] = useState(""); // State to show success/error message
  const [isLoading, setIsLoading] = useState(false); // State for loading spinner

  const sendEmail = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior

    if (!email) {
      setMessage("Please enter a valid email address");
      return;
    }

    setIsLoading(true); // Start loading spinner
    setMessage(""); // Clear previous messages

    const data = {
      email_group: "InitialNewsletterSubscribers",
      email: email,
    };

    try {
      // Make the API request using Axios
      const response = await axios.post(
        "https://api-gw.tomatech.online/v1/newsletter/addSubscriber",
        data
      );
      if (response.status === 200) {
        setMessage("Thank you for subscribing! Check your email for updates.");
      } else {
        setMessage("Something went wrong. Please try again later.");
      }
    } catch (error) {
      console.error("Error subscribing:", error);
      setMessage("An error occurred. Please try again later.");
    } finally {
      setIsLoading(false); // Stop loading spinner
    }
  };

  const [isHovered, setIsHovered] = useState(false);

  const foodItems = [
    "Toma Legal Database",
    "Customizable Templates",
    "Toma Library",
    "Toma AI",
    "Toma Vault",
  ];

  // Countdown target start and end dates
  const countdownStart = new Date("2024-09-24T20:19:00"); // September 24, 2024, 8:19 PM
  const countdownEnd = new Date("2024-10-15T23:59:00"); // October 15, 2024, 11:59 PM

  return (
    <div className="bg-[#bfbec8] overflow-hidden">
      <div
        style={{ backgroundImage: `url(${bg})` }}
        className=" bg-cover flex justify-center items-center"
      >
        <div
          className="w-full font-bold text-white tracking-widest text-center"
          style={{ fontFamily: "'Red Hat Text', sans-serif" }}
        >
          <div className="flex items-center justify-center mt-10">
            <img className="h-40 w-40 mr-2" src={logo} alt="Logo" />
          </div>
          <h1 className="lg:text-3xl md:text-2xl mt-10 tracking-[10px] mb-20 text-[#F2F2F2]">
            WE'RE LAUNCHING SOON
          </h1>
          <div className="text=[#8486A9]">
            <FlipCountdown
              hideYear
              hideMonth
              theme="dark"
              size="large"
              titlePosition="bottom"
              endAt={countdownEnd.toUTCString()} // Set the countdown end date
              dayTitle="DAYS"
              hourTitle="HOURS"
              minuteTitle="MINUTES"
              secondTitle="SECONDS"
            />
          </div>
          <p className=" text-gray-600 text-center mt-20 mb-10 mx-4 xl:px-80">
            Be the first to explore our new products with a 2-week free access{" "}
            
            after launch! Join our mailing list to stay updated and be a part of
            the excitement.
          </p>
          <form onSubmit={sendEmail} className="max-w-md mx-auto">
            <div className="flex items-center">
              <input
                type="email"
                className="text-[#000] bg-gray-100 mr-3 py-4 px-4 w-full rounded-md focus:outline-none focus:bg-white"
                placeholder="Enter your email"
                value={email} // Bind input value to state
                onChange={(e) => setEmail(e.target.value)} // Update state on input change
              />
              <button
                type="submit"
                className="bg-gradient-to-r from-orange-500 to-red-800 text-white py-4 px-4 rounded-md hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-opacity-50"
                disabled={isLoading} // Disable button while loading
              >
                {isLoading ? "Subscribing..." : "Subscribe"}
              </button>
            </div>
          </form>
          {message && (
            <p
              className={`mt-4 ${
                message.includes("error") ? "text-red-500" : "text-black"
              }`}
            >
              {message}
            </p>
          )}

          {/* Features section */}
          <section className="py-20">
            <div className="container mx-auto px-4">
              <div className="max-w-2xl mx-auto text-center">
                <h2 className="text-3xl font-bold mb-6 text-black">
                  What to expect
                </h2>
                <p className="text-white ">
                  TOMA stands out as the ultimate legal compliance solution
                  designed to meet the unique needs of every user.With TOMA,
                  navigate legal complexities effortlessly, stay ahead of
                  regulations, and ensure compliance with confidence.
                </p>
              </div>
            </div>
          </section>
          {/* <div
            className="relative overflow-hidden h-16 w-full bg-gray-100 flex items-center mb-10"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <div
              className={`flex space-x-10 whitespace-nowrap ${
                isHovered ? "animation-paused" : "animate-marquee"
              }`}
            >
              {foodItems.map((food, index) => (
                <span
                  key={index}
                  className="text-xl font-semibold text-gray-700"
                >
                  {food}
                </span>
              ))}
            </div>
          </div> */}
        </div>
      </div>
      <div
        style={{ backgroundImage: `url(${bottomBg})` }}
        className="h-[25vh] bg-no-repeat bg-cover flex justify-center items-center"
      >
        <div className="flex gap-x-4 text-[#8486A9]">
          <div>
            <i className="fa-brands fa-square-facebook text-3xl hover:text-[#F16A8C] hover:rotate-[360deg] transition-all  duration-500"></i>
          </div>
          <div>
            <i className="fa-brands fa-pinterest text-3xl hover:text-[#F16A8C] hover:rotate-[360deg] transition-all transform duration-500"></i>
          </div>
          <div>
            <i className="fa-brands fa-instagram text-3xl hover:text-[#F16A8C] hover:rotate-[360deg] transition-all transform duration-500"></i>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
